import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Theme,
  Link,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '60px 60px 0 60px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '20px 10px 0 10px',
      },
    },
    header: {
      fontWeight: 'bold',
      fontSize: '36px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    cards: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      marginTop: '50px',
      marginBottom: '200px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        marginBottom: '50px',
        flexDirection: 'column',
      },
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active': {
        textDecoration: 'none',
      },
    },
    card: {
      width: '280px',
      height: '174px',
      border: `1px solid ${colors.sparkBlue}`,
      borderRadius: '5px',
      boxShadow: `0px 1px 3px ${colors.boxShadowColor}`,
      marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      alignContent: 'baseline',
      '&:hover': {
        background: colors.backgroundLightBlue,
        boxShadow: `4px 3px 3px ${colors.boxShadowColor}`,
      },
      [theme.breakpoints.down('sm')]: {
        width: '333px',
        height: '144px',
        marginBottom: '20px',
        marginLeft: '0',
      },
    },
    cardTitle: {
      height: 'fit-content',
      marginTop: '20px',
      color: colors.brightBlue,
      fontSize: '18px',
      fontWeight: 'bold',
    },
    cardDescription: {
      height: 'fit-content',
      color: colors.noirBlurLight,
      textAlign: 'center',
      marginTop: '10px',
    },
  }),
);

const SSMLanding: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleStartServiceClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    void navigate(ROUTES.SSM_START_ADDRESS);
  };

  const handleMoveServiceClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    void navigate(ROUTES.SSM_MOVE);
  };

  const handleStopServiceClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    void navigate(ROUTES.SSM_STOP);
  };

  return (
    <>
      <Helmet>
        <title>{t('SSM_LANDING_TITLE')}</title>
      </Helmet>
      <Grid container className={classes.container}>
        <Typography className={classes.header} data-testid="header">
          {t('HOW_CAN_WE_HELP_YOU')}
        </Typography>
        <Grid container item className={classes.cards}>
          <Link
            onClick={handleStartServiceClick}
            href={ROUTES.SSM_START}
            className={classes.link}
            data-testid="start-link"
          >
            <Grid container item className={classes.card}>
              <Typography
                className={classes.cardTitle}
                data-testid="start-title"
              >
                {t('NEW_SERVICE')}
              </Typography>
              <Typography
                className={classes.cardDescription}
                data-testid="start-desc"
              >
                {t('START_SERVICE_AT_A_NEW_ADDRESS')}
              </Typography>
            </Grid>
          </Link>
          <Link
            onClick={handleMoveServiceClick}
            href={ROUTES.SSM_MOVE}
            className={classes.link}
            data-testid="move-link"
          >
            <Grid container item className={classes.card}>
              <Typography
                className={classes.cardTitle}
                data-testid="move-title"
              >
                {t('MOVE_SERVICE')}
              </Typography>
              <Typography
                className={classes.cardDescription}
                data-testid="move-desc"
              >
                {t('MOVE_SERVICE_LANDING_DESC')}
              </Typography>
            </Grid>
          </Link>
          <Link
            onClick={handleStopServiceClick}
            href={ROUTES.SSM_STOP}
            className={classes.link}
            data-testid="stop-link"
          >
            <Grid container item className={classes.card}>
              <Typography
                className={classes.cardTitle}
                data-testid="stop-title"
              >
                {t('STOP_SERVICE')}
              </Typography>
              <Typography
                className={classes.cardDescription}
                data-testid="stop-desc"
              >
                {t('SSM_STOP_SERVICE_LANDING_DESC')}
              </Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default SSMLanding;
